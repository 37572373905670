@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat/Montserrat-Light.eot');
	src: url('../fonts/Montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat/Montserrat-Light.woff') format('woff'),
		url('../fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat/Montserrat-Regular.eot');
	src: url('../fonts/Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat/Montserrat-Regular.woff') format('woff'),
		url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat/Montserrat-Medium.eot');
	src: url('../fonts/Montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat/Montserrat-Medium.woff') format('woff'),
		url('../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat/Montserrat-SemiBold.eot');
	src: url('../fonts/Montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat/Montserrat-SemiBold.woff') format('woff'),
		url('../fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat/Montserrat-Bold.eot');
	src: url('../fonts/Montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat/Montserrat-Bold.woff') format('woff'),
		url('../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway/Raleway-Bold.eot');
	src: url('../fonts/Raleway/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Raleway/Raleway-Bold.woff') format('woff'),
		url('../fonts/Raleway/Raleway-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway/Raleway-Medium.eot');
	src: url('../fonts/Raleway/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Raleway/Raleway-Medium.woff') format('woff'),
		url('../fonts/Raleway/Raleway-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

